import {
  useCallback,
  useMemo,
  useState,
  useRef,
  memo,
  FC,
  useEffect,
  ChangeEvent,
} from 'react';
import {
  getColor,
  ModalProps,
  ModalRef,
  RadioGroup,
  StatusElement,
  Tabs,
  Tooltip,
  useCallbackRef,
} from '@faxi/web-component-library';
import {
  Form,
  FormField,
  FormRef,
  useFormRefValues,
  validators,
} from '@faxi/web-form';
import { isEqual } from 'lodash';

import { useCallbackAsync, useFormButtons } from 'hooks';
import { snackBarSuccessMessage } from 'utils';
import { Icon, InputField, RadioGroupField, SwitchField } from 'components';
import { VerificationType } from 'models/Community';
import { apiCommunities } from 'modules';
import {
  minValue,
  maxValue,
  decimalsAllowed,
  numberValidation,
} from 'validation/validations';
import specific, { composeValidators } from 'validation/validators/specific';
import {
  convertNumbers,
  PERCANTAGE_FIELDS,
  TOOLTIP_TITLES,
  SETTINGS_DEFAULT_VALUES,
  VERIF_GRADE,
} from './utils';

import { FormActions } from 'Global.styles';
import * as Styled from './CommunitySettingsModal.styles';

const JOURNEY_VERIFICATION_TABS_OPTION = [
  {
    label: 'Easy',
    value: 'easy',
    body: (
      <>
        <span className="community-setting-modal__chip">
          After <b>20%</b> of carpooling
        </span>
        <span className="community-setting-modal__chip">
          After <b>1%</b> of walking or cycling
        </span>
      </>
    ),
  },
  {
    label: 'Medium',
    value: 'medium',
    body: (
      <>
        <span className="community-setting-modal__chip">
          After <b>40%</b> of carpooling
        </span>
        <span className="community-setting-modal__chip">
          After <b>25%</b> of walking or cycling
        </span>
      </>
    ),
  },
  {
    label: 'Hard',
    value: 'hard',
    body: (
      <>
        <span className="community-setting-modal__chip">
          After <b>60%</b> of carpooling
        </span>
        <span className="community-setting-modal__chip">
          After <b>50%</b> of walking or cycling
        </span>
      </>
    ),
  },
  {
    label: 'Custom',
    value: 'custom',
    body: (
      <span style={{ fontSize: '11px' }}>
        Set custom values for journey verification
      </span>
    ),
  },
];

export type CommunitySettingsType = {
  radius: string;
  verifafter: string;
  gps_nbseg: string;
  gps_percseg: string;
  gps_percdist: string;
  ble_threshold: string;
  cycle_min: string;
  walking_min: string;
  caw_threshold: string;
  wallet_enabled: 'N';
  verification_type?: VerificationType;
  formatted_address?: string;
  caw_distance_calculation?: 'real' | 'fixed';
  co2_per_km?: string | null;
  nox_per_km?: string | null;
  organisation_emissions?: {
    co2: string | null;
    nox: string | null;
  };
  platform_emissions?: {
    co2: string;
    nox: string;
  };
  customCO2data?: boolean;
  customNOXdata?: boolean;
};

type CommunitySettingsModalProps = {
  className?: string;
  communityId?: number;
  onSubmit: (cid: number, verification_type: VerificationType) => void;
} & ModalProps;

const CommunitySettingsModal: FC<CommunitySettingsModalProps> = (props) => {
  const { className, communityId, onSubmit, ...rest } = props;

  const modalRef = useRef<ModalRef>(null);

  const [form, formRef] = useCallbackRef<FormRef>();
  const [FormButtons] = useFormButtons('Save changes', 'Cancel');

  const [initialData, setInitialData] = useState<
    Partial<CommunitySettingsType>
  >({});

  const customCO2data = useFormRefValues(form, 'customCO2data')?.customCO2data;
  const customNOXdata = useFormRefValues(form, 'customNOXdata')?.customNOXdata;

  // TO DO: Move this to common validations
  const validations = useMemo(
    () => ({
      co2: composeValidators(
        specific.decimalsAllowed('Please enter a valid positive number'),
        specific.canNotStartWithMoreThenOneZero(
          'Please enter a valid positive number'
        ),
        (value, fields) => {
          if (fields?.customCO2data?.value && !value) {
            return 'This field is required';
          }
        },
        specific.minNumber('Minimum value is 0.0001', 0.0001),
        specific.maxNumber('Maximum value is 99', 99),
        validators.general.maxLength(13, 'Maximum length is 13')
      ),
      nox: composeValidators(
        specific.decimalsAllowed('Please enter a valid positive number'),
        specific.canNotStartWithMoreThenOneZero(
          'Please enter a valid positive number'
        ),
        (value, fields) => {
          if (fields?.customNOXdata?.value && !value) {
            return 'This field is required';
          }
        },
        specific.minNumber('Minimum value is 0.0001', 0.0001),
        specific.maxNumber('Maximum value is 99', 99),
        validators.general.maxLength(13, 'Maximum length is 13')
      ),
    }),
    []
  );

  const [loadCommunitySettings, loadingCommunities] = useCallbackAsync({
    showSpinner: false,
    callback: async (oid: string) => {
      const {
        data: { data: settings },
      } = await apiCommunities.getCommunityJSON(oid);

      delete (settings as Partial<CommunitySettingsType>)['wallet_enabled'];
      delete (settings as Partial<CommunitySettingsType>)['formatted_address'];

      //cast all settings values to string, so we can check equality
      const stringValueSettings = convertNumbers<CommunitySettingsType>(
        settings,
        PERCANTAGE_FIELDS,
        'string'
      );

      //independent fields
      delete (stringValueSettings as Partial<CommunitySettingsType>)['radius'];
      delete (stringValueSettings as Partial<CommunitySettingsType>)[
        'organisation_emissions'
      ];
      delete (stringValueSettings as Partial<CommunitySettingsType>)[
        'platform_emissions'
      ];

      let verifGrade = settings.verification_type || 'custom';

      // check if saved settings falls under one of VERIF_GRADE
      Object.entries(VERIF_GRADE).forEach((obj) => {
        if (isEqual(stringValueSettings, obj[1])) {
          verifGrade = obj[0] as VerificationType;
        }
      });

      const caw_distance_calculation =
        settings.caw_distance_calculation || 'real';

      // enable coefficients switch if custom values are set per community
      settings.organisation_emissions?.co2 &&
        form?.updateValueField('customCO2data', true);
      settings.organisation_emissions?.nox &&
        form?.updateValueField('customNOXdata', true);

      // if not prefill settings fields with values from BE
      if (verifGrade === 'custom') {
        setInitialData({
          ...convertNumbers<CommunitySettingsType>(
            {
              ...SETTINGS_DEFAULT_VALUES,
              ...settings,
            },
            PERCANTAGE_FIELDS,
            'mul'
          ),
          verification_type: verifGrade,
          caw_distance_calculation,
          platform_emissions: settings.platform_emissions,
          co2_per_km: `${settings.organisation_emissions?.co2 ?? ''}`,
          nox_per_km: `${settings.organisation_emissions?.nox ?? ''}`,
          customCO2data: !!settings.organisation_emissions?.co2,
          customNOXdata: !!settings.organisation_emissions?.nox,
        });
      } else {
        // if is one of predefined grade, set form values to default
        // in case user change to custom we want to show them custom values
        setInitialData({
          ...convertNumbers<CommunitySettingsType>(
            {
              ...(SETTINGS_DEFAULT_VALUES as CommunitySettingsType),
              radius: settings.radius,
            },
            PERCANTAGE_FIELDS,
            'mul'
          ),
          verification_type: verifGrade,
          caw_distance_calculation,
          platform_emissions: settings.platform_emissions,
          co2_per_km: `${settings.organisation_emissions?.co2 ?? ''}`,
          nox_per_km: `${settings.organisation_emissions?.nox ?? ''}`,
          customCO2data: !!settings.organisation_emissions?.co2,
          customNOXdata: !!settings.organisation_emissions?.nox,
        });
      }
    },
  });

  const [handleOnSubmit, loadingSubmit] = useCallbackAsync({
    showSpinner: false,
    callback: async (values: CommunitySettingsType) => {
      const {
        verification_type,
        caw_distance_calculation,
        co2_per_km,
        nox_per_km,
        customCO2data,
        customNOXdata,
        ...rest
      } = values;

      if (verification_type === 'custom') {
        await apiCommunities.updateCommunityJSON(
          Number(communityId),
          JSON.stringify({
            verification_type,
            caw_distance_calculation,
            co2_per_km: customCO2data ? Number(co2_per_km) || null : null,
            nox_per_km: customNOXdata ? Number(nox_per_km) || null : null,
            ...convertNumbers<CommunitySettingsType>(
              rest,
              PERCANTAGE_FIELDS,
              'div'
            ),
          })
        );
      } else {
        //if verifGrade is not custom,
        //set predefined values to BE except radius which is independent field
        await apiCommunities.updateCommunityJSON(
          Number(communityId),
          JSON.stringify({
            ...VERIF_GRADE[`${verification_type!}`],
            verification_type,
            caw_distance_calculation,
            radius: rest.radius,
            co2_per_km: customCO2data ? Number(co2_per_km) || null : null,
            nox_per_km: customNOXdata ? Number(nox_per_km) || null : null,
          })
        );
      }

      snackBarSuccessMessage('Changes has been successfully saved');
      onSubmit(Number(communityId), verification_type!);
      modalRef.current?.close();
    },
  });

  const formWrapper = useCallback(
    ({ children }: any) => (
      <Form
        ref={formRef}
        onSubmit={handleOnSubmit}
        initialData={initialData}
        strictValidation={false}
        children={children}
      />
    ),
    [initialData, formRef, handleOnSubmit]
  );

  const disableSubmitButton = useMemo(() => {
    if ((initialData as any)?.verification_type !== 'custom') {
      if (
        form?.fields?.verification_type?.value ===
          (initialData as any)?.verification_type &&
        form?.fields?.radius?.value === (initialData as any)?.radius &&
        form?.fields?.caw_distance_calculation?.value ===
          (initialData as any)?.caw_distance_calculation &&
        form?.fields?.co2_per_km?.value === (initialData as any)?.co2_per_km &&
        form?.fields?.nox_per_km?.value === (initialData as any)?.nox_per_km
      ) {
        return true;
      }
    } else
      return !form?.isFormChanged([
        'platform_emissions',
        'organisation_emissions',
        'customCO2data',
        'customNOXdata',
      ]);
  }, [form, initialData]);

  useEffect(() => {
    if (communityId) {
      loadCommunitySettings(communityId);
    }
  }, [communityId, loadCommunitySettings]);

  return (
    <Styled.CommunitySettingsModal
      className="community-setting-modal"
      loading={loadingCommunities || loadingSubmit}
      title="Settings"
      ref={modalRef}
      childrenWrapper={formWrapper}
      footer={
        <FormActions className="kinto-modal__actions">
          <FormButtons.Submit
            disabled={disableSubmitButton || !form?.syncFormValid}
          />
          <FormButtons.Cancel onClick={() => modalRef.current?.close()} />
        </FormActions>
      }
      {...rest}
    >
      <div className="community-setting-modal__form">
        <div className="community-setting-modal__form__fields">
          <FormField
            name="radius"
            label="More info"
            labelPosition="right"
            placeholder="Radius (Meters)"
            tooltipContent={TOOLTIP_TITLES['radius']}
            component={InputField}
            validate={composeValidators(
              decimalsAllowed,
              minValue(0),
              maxValue(1000000)
            )}
          />
        </div>

        <div className="community-setting-modal__custom-coefficients">
          <h2 className="community-setting-modal__subtitle">
            Custom coefficients
          </h2>
          <span className="community-setting-modal__custom-coefficients__tooltip">
            More info
            <Tooltip
              placement="top"
              content="Enable custom coefficients for CO2 and NOx to apply specific values for emissions calculations in this community. 
              Once activated, all future journeys will use the new settings. To revert to the platform's default coefficients, simply switch off the custom coefficients."
            >
              <span>
                <Icon color={getColor('--PRIMARY_1_1')} name="circle-info" />
              </span>
            </Tooltip>
          </span>
          <div className="community-setting-modal__custom-coefficients__cards">
            <div className="community-setting-modal__custom-coefficients__card">
              <h2 className="community-setting-modal__custom-coefficients__card__title">
                CO₂
              </h2>
              <div className="community-setting-modal__custom-coefficients__card__group">
                <p className="community-setting-modal__custom-coefficients__card__label">
                  Platform coefficient
                </p>
                <StatusElement status="active">{`${
                  initialData.platform_emissions?.co2 || 'N/A'
                } kg per km`}</StatusElement>
              </div>

              <div className="community-setting-modal__custom-coefficients__card__group">
                <p className="community-setting-modal__custom-coefficients__card__label">
                  Custom coefficient
                </p>

                <FormField
                  name="customCO2data"
                  onChange={(ev: ChangeEvent<HTMLInputElement> | any) => {
                    if (ev.target.value) {
                      form?.updateValueField('co2_per_km', '');
                    }
                  }}
                  component={SwitchField}
                />
                <FormField
                  name="co2_per_km"
                  autoComplete="off"
                  placeholder="CO₂ coefficient"
                  disabled={!customCO2data}
                  component={InputField}
                  inputMode="numeric"
                  validate={validations.co2}
                  required
                  requiredLabel="Required"
                />
              </div>
            </div>
            <div className="community-setting-modal__custom-coefficients__card">
              <h2 className="community-setting-modal__custom-coefficients__card__title">
                NOx
              </h2>
              <div className="community-setting-modal__custom-coefficients__card__group">
                <p className="community-setting-modal__custom-coefficients__card__label">
                  Platform coefficient
                </p>
                <StatusElement status="active">{`${
                  initialData.platform_emissions?.nox || 'N/A'
                } kg per km`}</StatusElement>
              </div>

              <div className="community-setting-modal__custom-coefficients__card__group">
                <p className="community-setting-modal__custom-coefficients__card__label">
                  Custom coefficient
                </p>
                <FormField
                  name="customNOXdata"
                  component={SwitchField}
                  onChange={(event: ChangeEvent<HTMLInputElement> | any) => {
                    if (event.target.value) {
                      form?.updateValueField('nox_per_km', '');
                    }
                  }}
                />
                <FormField
                  name="nox_per_km"
                  autoComplete="off"
                  placeholder="NOx coefficient"
                  disabled={!customNOXdata}
                  component={InputField}
                  inputMode="numeric"
                  validate={validations.nox}
                  required
                  requiredLabel="Required"
                />
              </div>
            </div>
          </div>
        </div>

        <div>
          <h2 className="community-setting-modal__subtitle">
            Journey verification
          </h2>
          <FormField
            name="verification_type"
            tabs={JOURNEY_VERIFICATION_TABS_OPTION}
            component={Tabs}
            checkedIcon={<Icon name="circle-check-fas" />}
          />
        </div>

        {form?.fields?.verification_type?.value === 'custom' && (
          <>
            <fieldset>
              <h2 className="community-setting-modal__subtitle">Carpooling</h2>
              <div className="community-setting-modal__form__fields">
                <FormField
                  name="verifafter"
                  label="More info"
                  labelPosition="right"
                  placeholder="Verify journey check after"
                  tooltipContent={TOOLTIP_TITLES['verifafter']}
                  component={InputField}
                  validate={composeValidators(
                    decimalsAllowed,
                    minValue(0),
                    maxValue(100)
                  )}
                />

                <FormField
                  name="gps_nbseg"
                  label="More info"
                  labelPosition="right"
                  placeholder="gps_nbseg"
                  tooltipContent={TOOLTIP_TITLES['gps_nbseg']}
                  component={InputField}
                  validate={composeValidators(
                    numberValidation,
                    minValue(0),
                    maxValue(1000)
                  )}
                />

                <FormField
                  name="gps_percseg"
                  label="More info"
                  labelPosition="right"
                  placeholder="gps_percseg (%)"
                  tooltipContent={TOOLTIP_TITLES['gps_percseg']}
                  component={InputField}
                  validate={composeValidators(
                    decimalsAllowed,
                    minValue(0),
                    maxValue(100)
                  )}
                />

                <FormField
                  name="gps_percdist"
                  label="More info"
                  labelPosition="right"
                  placeholder="gps_percdist (%)"
                  tooltipContent={TOOLTIP_TITLES['gps_percdist']}
                  component={InputField}
                  validate={composeValidators(
                    decimalsAllowed,
                    minValue(0),
                    maxValue(100)
                  )}
                />

                <FormField
                  name="ble_threshold"
                  label="More info"
                  labelPosition="right"
                  placeholder="Bluetooth treshold (%)"
                  tooltipContent={TOOLTIP_TITLES['ble_threshold']}
                  component={InputField}
                  validate={composeValidators(
                    decimalsAllowed,
                    minValue(0),
                    maxValue(100)
                  )}
                />
              </div>
            </fieldset>

            <fieldset className="community-setting-modal__fields">
              <h2 className="community-setting-modal__subtitle">
                Walking & Cycling
              </h2>
              <div className="community-setting-modal__form__fields">
                <FormField
                  name="cycle_min"
                  label="More info"
                  labelPosition="right"
                  placeholder="Cycling verification check (Meters)"
                  tooltipContent={TOOLTIP_TITLES['cycle_min']}
                  component={InputField}
                  validate={composeValidators(
                    decimalsAllowed,
                    minValue(0),
                    maxValue(100000)
                  )}
                />

                <FormField
                  name="walking_min"
                  label="More info"
                  labelPosition="right"
                  placeholder="Walking verification check (Meters)"
                  tooltipContent={TOOLTIP_TITLES['walking_min']}
                  component={InputField}
                  validate={composeValidators(
                    decimalsAllowed,
                    minValue(0),
                    maxValue(100000)
                  )}
                />

                <FormField
                  name="caw_threshold"
                  label="More info"
                  labelPosition="right"
                  placeholder="Cycling and walking treshold (%)"
                  tooltipContent={TOOLTIP_TITLES['caw_threshold']}
                  component={InputField}
                  validate={composeValidators(
                    decimalsAllowed,
                    minValue(0),
                    maxValue(100)
                  )}
                />
              </div>
            </fieldset>
          </>
        )}

        <div className="community-setting-modal__journey-distance">
          <h2 className="community-setting-modal__subtitle">
            Journey distance counting
          </h2>

          <div className="community-setting-modal__journey-distance__fields">
            <div className="community-setting-modal__journey-distance__th">
              Mode
            </div>
            <div className="community-setting-modal__journey-distance__values">
              <div>
                Fixed
                <Tooltip
                  placement="top"
                  content="The journey distance is measured from the start to the end point.
                  The distance is calculated by Google."
                >
                  <span>
                    <Icon
                      color={getColor('--PRIMARY_1_1')}
                      name="circle-info"
                    />
                  </span>
                </Tooltip>
              </div>

              <div>
                Detected (GPS)
                <Tooltip
                  placement="top"
                  content="The journey distance is measured by the device's GPS.
                      It calculates the distance between all detected locations
                      for the chosen mode of transport."
                >
                  <span>
                    <Icon
                      color={getColor('--PRIMARY_1_1')}
                      name="circle-info"
                    />
                  </span>
                </Tooltip>
              </div>
            </div>

            <div className="community-setting-modal__journey-distance__labels">
              Carpooling
            </div>

            <RadioGroup
              options={[
                { label: '', value: 'fixed' },
                { label: '', value: 'real' },
              ]}
              disabled={true}
              value={'fixed'}
            />

            <div className="community-setting-modal__journey-distance__labels">
              Walking & Cycling
            </div>

            <FormField
              component={RadioGroupField}
              className="kinto-page__search-param"
              name="caw_distance_calculation"
              orientation="row"
              options={[
                { label: '', value: 'fixed' },
                { label: '', value: 'real' },
              ]}
            />
          </div>
        </div>
      </div>
    </Styled.CommunitySettingsModal>
  );
};

export default memo(CommunitySettingsModal);
